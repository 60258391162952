import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Styled from "styled-components"
import { Helmet } from "react-helmet"

const Container = Styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  @media all and (min-width: 992px) {
    padding: 50px 75px;
    flex-direction: row;
  }

  p {
    color:${props => props.theme.headerText};
  }

  a { color:${props => props.theme.headerText}; text-decoration: none; }

  h3 {
    color: white
  }
`

const SideContact = Styled.div`
  flex: 1;
  padding: 0;

  

  @media all and (min-width: 992px) {
    padding-left: 40px;
  }
`

const title = "Contact"

const Contact = () => {
  return (
    <Layout activeMenu={title}>
      <SEO title="Contact Us" />
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Balreet +6012.202.5334 balreet@samballab.com Jodh +6012.210.7489 jodh.dheensay@samballab.com"
        />
      </Helmet>
      <Container>
        <SideContact>
          <h3>Say hello</h3>
          <p>
            <a href="mailto:talk2us@samballab.com">talk2us@samballab.com</a>
          </p>

          {/* <h3>
            <FontAwesomeIcon icon={faEnvelope} />
            New business
          </h3>
          <p>
            <a href="mailto:jodh.dheensay@samballab.com">
              jodh.dheensay@samballab.com
            </a>
          </p> */}

          <h3>General</h3>
          <p>
            <a href="tel:+60367385993">tel +603.6738.5993</a>
            <br />
            fax +603.6203.9489
          </p>
          <p>
            7 Laman Tunku
            <br />
            Bukit Tunku
            <br />
            50480 Kuala Lumpur
            <br />
            Malaysia
          </p>
          <p>
            Sambal Lab Sdn Bhd <br />
            895096-A
          </p>
        </SideContact>
      </Container>
    </Layout>
  )
}

export default Contact
